@import "../assets/css/variable.scss";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "primeicons/primeicons.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/**Reset scss**/
:root {
  --bg: #8080800d;
  --green: #008000;
  --background: #dfdfdf;
  --bodybg: #f3f3f3;
  --primary-color: #e50019;
  --secondary: #4162c2;
  --black: #000;
  --semi-black: #0000004d;
  --danger: #f53d53;
  --yellow: #ffaf3d;
  --grey: #828282;
  --grey-light: #f0f0f0;
  --white: #fff;
  --danger-light: #fa8a97;
  --border-color: #dedede;
  --border-light: #ededed;
  --border-white: #fff;
  --border: #ebebeb;
  --content: #828282;
  --content-light: #1c1c1c;
  --button-bg: #efefef;
  --expire-vl: #1ebad7;
  --highlight-text-color: #e50019 !important;
}

.primary_color {
  color: var(--primary-color) !important;
  background: transparent;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover,
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-color) !important;
  background: var(--primary-color) !important;
  color: #ffffff;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color);
  --mdc-checkbox-selected-icon-color: var(--primary-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-color);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-color);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-color);
}

input {
  text-align: center;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
}

body,
* {
  font-family: "Poppins", serif;
}

.cp {
  cursor: pointer;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
ol {
  padding: 0;
}

p-dropdown .p-dropdown,
p-multiselect,
.p-multiselect {
  border-radius: 30px;
  width: 100%;
}

.multi_selected {

  p-multiselect,
  .p-multiselect {
    width: 103% !important;
  }
}

p-calendar {
  span {
    width: 100%;
  }

  input {
    border-radius: 30px;
  }
}

.p-multiselect .p-multiselect-label {
  text-align: center;
  width: 100%;
}

svg.p-dropdown-clear-icon.p-icon {
  transform: translate(0px, -7px);
}

body:has(.p-sidebar-active) {
  .p-sidebar-mask {
    position: unset;
  }
}

.pi {
  font-family: "primeicons" !important;
}

.driver-header {
  margin: 20px 0;
  border: 1px solid var(--border-color);
  border-radius: 30px;

  p-button {
    width: 100%;

    button {
      width: 100%;
      border-radius: 30px;
      background: transparent;
      color: #6c757d;
      border: none;
      display: inline-flex;
      justify-content: center;
      text-transform: capitalize;

      &:focus {
        border: 2px solid var(--primary-color);
        outline: var(--primary-color);
        color: var(--primary-color);
      }

      &:focus-visible {
        border: 2px solid var(--primary-color);
        outline: var(--primary-color);
        color: var(--primary-color);
      }
    }
  }

  .pi {
    font-weight: 700;
  }
}

legend {
  color: #000;
  font-weight: 600;
  font-size: 22px;
}

fieldset {
  border-color: var(--border-color);
  border-radius: 10px;
  margin-top: 20px;
}

.p-button:focus {
  box-shadow: none !important;
  outline: none !important;
}

input:focus {
  outline: none !important;
  border: 2px solid var(--primary-color);
}

.p-inputtext:enabled:focus {
  box-shadow: unset;
  border: 2px solid var(--primary-color);
}

.p-multiselect-filter-container {
  input {
    border-radius: 30px;
  }

  svg.p-icon.p-multiselect-filter-icon {
    top: 16px;
  }
}

.p-dropdown-header .p-dropdown-filter-container {
  input {
    border-radius: 30px;
  }

  svg.p-dropdown-filter-icon.p-icon {
    top: 16px;
  }
}

.p-multiselect:focus,
.p-multiselect:active,
.p-multiselect:hover,
.p-multiselect:focus-visible {
  box-shadow: var(--primary-color) !important;
  /* Remove focus shadow */
  outline: var(--primary-color);
  /* Remove focus outline */
  border-color: var(--primary-color);
  /* Hide border change on focus */
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--primary-color) !important;
  background: #e500190d !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--primary-color) !important;
  background: #e500190d !important;
}

.p-button:focus {
  box-shadow: none !important;
  outline: none !important;
}

ngx-intl-tel-input .iti.iti--allow-dropdown.separate-dial-code:focus {
  border: 2px solid var(--primary-color);
}

ngx-intl-tel-input .iti.iti--allow-dropdown.separate-dial-code:focus-visible {
  border: 2px solid var(--primary-color);
}

.iti.separate-dial-code input:focus,
.iti.separate-dial-code input:focus-visible {
  border: 2px solid var(--primary-color);
}

ngx-intl-tel-input:focus {
  border: 2px solid var(--primary-color);
}

ngx-intl-tel-input:focus-visible {
  border: 2px solid var(--primary-color);
}

input[type="checkbox"] {
  accent-color: var(--primary-color);
}

/**custom scss start**/

.input_flag {
  .multi_selected {
    position: absolute;
    top: 3px;
    width: 80px;
    height: 40px;
    left: 10px;

    p-multiselect {
      height: 100%;

      .p-multiselect {
        height: 100%;
        border: unset;
        border-radius: unset;
        border-right: 1px solid var(--border-color);
      }
    }

    .p-multiselect-items {
      p-multiselectitem {
        figure {
          width: 20px;
        }
      }
    }
  }
}

.card {
  padding: 15px 0;
  border-radius: 25px;
  height: 100%;
  @include d_flx(center, center);
  flex-direction: column;
  width: 100%;
  position: relative;
  background: #fff;

  .bck_btn {
    position: absolute;
    top: 30px;
    left: 0;
    margin: 0 10px 10px 0;
    width: 30px;
    height: 30px;
    @include d_flx(center, center);
    flex-wrap: nowrap;
    overflow: hidden;
    border-radius: 30px;
  }
}

.p-toast .p-toast-message .p-toast-message-content {
  gap: 12px;
  line-height: 20px;
  align-items: center;
}

// .p-inputtext.ng-dirty.ng-invalid {
//   border-color: var(--white);
//   padding: 0;
// }

.confirm-dilouge {
  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    font-size: 14px;
    font-weight: 600;
    color: var(--black);
  }

  .p-confirm-dialog.p-component {
    width: 407px;

    .p-dialog-footer {
      display: flex;
      justify-content: center;
      gap: 12px;
    }
  }

  .p-dialog-header {
    padding: 0.5rem;
  }

  .p-dialog-content {
    flex-direction: column;
    padding-bottom: 20px;
    gap: 15px;
  }

  i.pi.pi-exclamation-triangle {
    color: var(--danger);
    font-size: 42px;
  }

  i.pi.pi-exclamation-circle {
    color: var(--danger);
    font-size: 42px;
  }
}

/*custom rest css */
ngx-intl-tel-input.p-inputtext.p-component.p-element {
  border: unset;
  padding: 0;
}

input.p-inputtext.p-component.p-element.p-password-input {
  width: 100%;
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  min-height: 45px;
  border-radius: 30px;

  &:focus {
    border: 2px solid var(--primary-color);
  }

  &:focus-visible {
    border: 2px solid var(--primary-color);
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 30px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: 45px;
}

p-button {
  &:hover {
    transform: scale(1.05);
    transition: all 0.4s ease-in-out;
  }
}

.auth_sc {
  .auth_cntnt {
    width: 100%;
    @include d_flx(center, space-between);
    flex-wrap: nowrap;
    margin-top: 10px;

    span {
      font-size: 14px;
      font-weight: 500;
      color: #000;
      line-height: 30px;
      cursor: pointer;
      transition: all 0.4s all ease-in-out 0.4s;

      &:last-child {
        text-align: right;
      }

      &:hover {
        color: var(--primary-color);
        transform: translateY(-2px);
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .social-links {
    @include d_flx(center, center, 20px);

    a {
      width: 130px;
      height: 100px;
      max-height: 60px;
      display: block;

      img {
        @include img_contain(cover);
      }

      &:hover {
        transform: scale(1.05);
        transition: all 0.4s ease-in-out;
      }
    }
  }

  .iti.separate-dial-code input,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-2 input,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-3 input,
  .iti.separate-dial-code.iti--allow-dropdown.iti-sdc-4 input {
    padding-left: 0 !important;
  }
}

.auth_group {
  @include d_flx(center, center, 20px);
  flex-direction: column;

  &.Vehicles_mn_spc {
    >.w-full {
      logibids-sp-module {
        &:first-child {
          .service-items {
            h5 {
              margin: 0;
              transform: unset;
            }
          }
        }
      }

    }
  }
}

.main_wrp {
  .mn_map_view {
    width: 100%;
    height: 100%;

    .contmap {
      height: 100vh !important;
    }
  }
}

.conta_iner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.navbar {
  background-color: var(--white);
  padding: 5px 20px;
  z-index: 9;
  position: fixed;
  right: 0;
  left: 0;

  .hd_logo {
    max-width: 180px;
  }

  .nav_rt {
    li {
      a {
        font-size: 20px;
        font-weight: 600;
        color: #000;

        figure {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid hsl(0, 0%, 87%);
          @include d_flx(center, center);
          overflow: hidden;
          cursor: pointer;

          img {
            @include img_contain(cover);
          }
        }

        button {
          width: 35px;
          height: 35px;
          @include d_flx(center, center);
          flex-wrap: nowrap;
          text-transform: uppercase;

          .pi {
            font-size: 16px;
          }
        }
      }

      &:hover {
        transform: translateY(-3px);
        transition: all 0.4s;
      }
    }
  }
}

.adddriver-change {
  .p-dropdown-label {
    padding-left: 50px;
  }
}

.form_input_tel {
  position: relative;

  .dropdown-menu.show {
    max-width: 230px;

    @media screen and (max-width: 1200px) {
      max-width: 200px;
    }
  }

  input#phone {
    padding-left: 70px !important;
  }
}

ngx-intl-tel-input {
  .iti.iti--allow-dropdown.separate-dial-code {
    border: 1px solid #ced4da;
    border-radius: 30px;
    width: 100%;

    input {
      width: 100%;
      padding-left: 0 !important;
      text-align: center;
    }
  }
}

.profile_drpdwn {
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    font-size: 14px;
    font-weight: 600;
    color: #000000bf;
    align-items: center;
  }

  li {
    @include d_flx(center, flex-start, 10px);
    border-bottom: 1px solid var(--border-color) 45;
    padding-bottom: 5px;
    cursor: pointer;
    width: 100%;

    figure {
      max-width: 20px;
      max-height: 20px;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      transform: translateY(-3px);
      transition: all 0.4s;
    }
  }
}

.g_map {
  display: none;
}

.main_wrp:has(.nomap) {
  .tracking_wp {
    @include d_flx(flex-start, flex-start);
    flex-wrap: nowrap;
    width: 100%;

    >.conta_iner {
      width: 46%;

      .track_card {
        width: 100%;
        max-height: calc(100vh - 90px);
        height: 100vh;
        overflow-y: auto;
        background: #fff;
        border-bottom: 1px solid rgba(213, 213, 213, 0.3882352941);
        box-shadow: rgba(17, 17, 26, 0.1098039216) 1px 6px 16px;
        border-radius: 10px;
        z-index: 2;
        position: relative;
        top: 70px;
        display: block;

        .bck_btn {
          left: 30px;
        }

        .form-group .btn_center {
          max-width: 249px;
          margin: 0 auto 0;
        }

        .map_cntnt {
          padding: 20px;
          text-align: center;
        }

        .logo figure {
          margin: 0 auto 20px;
        }
      }
    }

    .g_map {
      display: block;
      width: 53%;
      height: 100vh;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.card_view {
  max-width: 834px;
  width: 100%;
  max-height: calc(100vh - 90px);
  height: 100%;
  margin-left: 1.5rem;
  overflow-y: auto;
  background: #fff;
  border-bottom: 1px solid rgba(213, 213, 213, 0.3882352941);
  box-shadow: rgba(17, 17, 26, 0.1098039216) 1px 6px 16px;
  border-radius: 10px;
  z-index: 2;
  display: block;
  position: absolute;
  top: 75px;
}

.mn_notification {
  h4 {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 2px solid var(--primary-color);
    color: var(--black);
    line-height: normal;
  }

  .notification-item1 {
    margin-top: 30px;

    ul {
      width: 100%;
      margin-top: 50px;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid var(--border-color);
        padding: 1px;
        gap: 10px;
        margin: 10px 2px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
          border: none;
        }

        p {
          font-weight: 700;

          span {
            font-weight: 500;
          }
        }
      }
    }
  }
}

.vhl_mdl_sc {
  padding: 40px 0 0;

  .cat_bx {
    @include d_flx(center, space-between);
    padding: 5px 10px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    margin-bottom: 10px;

    p {
      font-size: 14px;
      color: var(--content);
      font-weight: 500;
      line-height: normal;
    }
  }
}

.form-group {
  .btn_center {
    max-width: 213px;
    margin: 20px auto 0;

    p-button {
      width: 100%;

      button {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        background-color: var(--primary-color) !important;
        text-transform: uppercase;
        font-size: 14px;
        border-radius: 30px;
        min-height: 45px;
      }
    }
  }

  .input-group {
    width: 100%;
    position: relative;

    input {
      text-align: center;
      width: 100%;
    }
  }
}

.addVehicelForm {
  .input-group {
    width: 100%;
    position: relative;

    input {
      width: 100%;
      text-align: center;
    }
  }
}

.sub_title {
  h4 {
    text-align: center;
    font-size: 18px;
    line-height: normal;
    margin: 20px 0;
  }
}

.btn_center {
  @include d_flx(center, center);
  margin: 40px 0 0;
  width: 100%;
}

.logo {
  figure {
    max-width: 160px;
    margin: 20px 0;

    img {
      @include img_contain(cover);
    }

    @media only screen and (max-width: 1400px) {
      max-width: 130px;
    }
  }
}

/*custom rest css */

/*panel custom scss start*/


.sp_sc {
  ul {
    @include d_flx(center, center, 20px);
    flex-direction: column;
    width: 100%;
    margin-bottom: 40px;

    >li {
      padding: 15px 20px;
      border: 1px solid var(--green);
      color: var(--green);
      width: 100%;
      border-radius: 30px;
      font-size: 15px;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      position: relative;
      cursor: pointer;
      letter-spacing: 0.4px;

      span {
        position: absolute;
        right: 30px;

        i {
          font-size: 18px;
          font-weight: 600;
        }
      }

      &.danger {
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
    }
  }
}

.title {
  margin: 20px 0;

  h2 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    color: var(--black);
    line-height: 30px;

    @media only screen and (max-width: 1400px) {
      font-size: 20px;
    }
  }

  p {
    text-align: center;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    margin: 5px 0;
    letter-spacing: 0.1px;
    line-height: normal;
  }
}

.file-upload {
  input#chooseFile {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  figure.info_icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .download {
    position: absolute;
    top: 8px;
    right: 37px;

    .p-button {
      width: 27px;
      height: 27px;
    }
  }

  .file-select-name {
    p {
      text-align: center;
      transform: translate(-10px, 5px);
    }
  }
}

.doc_wp {
  input[type="checkbox"] {
    accent-color: red;
    width: 15px;
    height: 15px;
    overflow: hidden;
  }

  .status {
    margin: 5px 0 20px;

    h4 {
      color: var(--green);
      font-size: 18px;
      font-weight: 600;
    }

    span {
      color: var(--black);
      font-size: 16px;
      font-weight: 500;
    }
  }

  .form-group {
    .input-group {
      margin-bottom: 20px;

      label {
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: normal;
        color: #2c3135;
      }

      &.id_field {
        .file-select-name {
          p {
            max-width: 210px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

label {
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
  display: block;
  letter-spacing: 0.1px;
  color: #000000db;
}

.service-items {
  .p-carousel-item {
    >div {
      cursor: pointer;
      margin: 0 20%;
      // margin: 30px 20% 20px;
      transition: margin 0.4s ease;
    }

    .slide_img {
      figure {
        max-width: 100%;
        min-height: 120px;
        margin: 30px 0 10px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        img {
          @include img_contain(contain);
          padding: 10px;
        }
      }
    }

    .moduleName {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      line-height: normal;
    }
  }

  .p-carousel-item.p-carousel-item-active:not(.p-carousel-item-start,
    .p-carousel-item-end) {

    img,
    .moduleName {
      transform: scale(1.6);
      transition: all 0.4s ease-in-out;
    }

    .moduleName {
      transform: scale(1.4);
      margin-top: 10px;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      width: 100%;
      margin: 10px 0;
    }

    .moduleName span {
      display: inline-block;
      white-space: nowrap;
    }

    .marquee-active span {
      transform: translateX(-50%);
      animation: marquee 8s linear infinite;
    }
  }

  .vehicle_hd {
    width: 100%;
    @include d_flx(center, center);
    margin: 10px 0;
    font-size: 22px;
    font-weight: 600;
    transform: translateY(10px);
    padding: 10px;
  }
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

.vhl_select {
  .vhl_list {
    border: 1px solid var(--border-color);
    width: 100%;
    text-align: center;
    padding: 10px;
    border-top: unset;
    box-shadow: rgba(17, 17, 26, 0.1098039216) 1px 6px 16px;
    max-height: 350px;
    overflow: auto;
    cursor: pointer;

    li {
      border-bottom: 1px solid var(--border-color) a1;
      width: 100%;
      padding: 7px 15px;
      min-height: 40px;

      &:last-child {
        border-bottom: unset;
      }

      p {
        font-size: 16px;
        color: #000000ad;
        font-weight: 500;
      }

      &:hover p {
        transform: translateY(-3px);
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

.my_vhl_sc {
  .title {
    margin-bottom: 20px;
  }

  .vhl_card {
    @include d_flx(center, space-between);
    flex-wrap: nowrap;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid var(--border-light);


    .vhl_rt {
      figure {
        max-width: 90px;
        max-height: 90px;
        overflow: hidden;
        border-radius: 10px;

        img {
          @include img_contain(contain);
        }
      }
    }

    .vhl_rtTrip {
      figure {
        width: 130px;
        max-height: 90px;
        overflow: hidden;
        border-radius: 10px;

        img {
          @include img_contain(contain);
        }
      }
    }

    .vhl_lt {
      h5 {
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        color: var(--black);
      }

      span {
        color: var(--black);
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
      }

      .p-button {
        margin-top: 10px;
        max-width: 100px;
        display: flex;
        justify-content: center;
        max-height: 38px;
        width: 100%;
      }
    }

    .status_flag {
      position: absolute;
      top: 0;
      right: 0;
      background: #00800026;
      padding: 6px;
      border-radius: 0px 10px 0 10px;
      max-width: 110px;
      width: 100%;
      text-align: center;
      color: #008000f5;
      font-weight: 600;

      &.right_rejected {
        background: #e500191c;
        color: var(--primary-color);
      }

      &.right_expired {
        background: #0000ff14;
        color: #0000fff7;
      }

      &.right_pending {
        background: rgb(255 255 0 / 38%);
        color: #9f9f0f;
      }
    }
  }

  &.cstmr_rating {
    width: 100%;

    .vhl_card {
      width: 100%;
      display: block;
      margin: 0;

      >li {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;

          p {
            span {
              white-space: normal;
              overflow-wrap: break-word;
              word-break: break-word;
              width: 540px;
              display: inline-block;
            }
          }
        }

        p {
          font-size: 16px;
          font-weight: 600;
          color: var(--black);
          line-height: normal;
          display: inline-flex;
          gap: 5px;

          span {
            font-weight: 400;
          }
        }
      }

    }
  }
}

.p-dropdown {
  .p-dropdown-label.p-placeholder {
    color: #6c757d;
    text-align: center;
  }

  &:focus {
    border: 2px solid var(--primary-color);
  }

  &:focus-visible {
    border: 2px solid var(--primary-color);
  }
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-color);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-align: center;
}

.p-dropdown-label {
  text-align: center;
}

.p-dropdown {
  .p-dropdown-empty-message {
    text-align: center;
  }
}

.p-password.p-component.p-inputwrapper.p-input-icon-right {
  width: 100%;

  svg.p-icon {
    position: relative;
    top: -6px;
    left: 0;
    bottom: 0px;
  }
}

.p-password {
  width: 100%;
}

input.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

input.disabled:hover,
input.disabled:focus {
  outline: none;
  box-shadow: none;
  border-color: initial;
}

/*profile scss start*/
.profile_cntnt {
  width: 100%;
  @include d_flx(center, center);
  flex-direction: column;
  width: 69% !important;

  figure {
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: 25px 0;
    border: 1px solid #bedede66;
    aspect-ratio: 1 / 1;

    img {
      @include img_contain(cover);
    }
  }

  span {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 50%;
    @include d_flx(center, center);
    flex-wrap: nowrap;
    color: #e50019;
    border: 1px solid #dedede;
    position: absolute;
    z-index: 9;
    bottom: 30px;
    right: 0;

    input#chooseFile {
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
    }
  }

  ul.profile-data {
    width: 100%;

    li {
      @include d_flx(center, center, 20px);
      flex-wrap: nowrap;
      flex-wrap: nowrap;
      width: 69%;
      margin: 0 auto 10px;
      transform: translate(-30px, 0px);

      strong {
        width: 50%;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        line-height: normal;
        text-align: right;
      }

      p {
        width: 50%;
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }
    }
  }
}

.edt_fdx {
  @include d_flx(center, center, 20px);
  flex-direction: column;
  text-align: center;
  border: 1px solid var(--border-color);
  margin: 20px 0;
  border-radius: 10px;
  padding: 20px;

  ul {
    margin: 20px 0;

    li {
      margin-bottom: 7px;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: normal;

      p-multiselect {
        p-multiselectitem {
          li {
            display: flex;
            gap: 18px;
          }
        }
      }

    }
  }
}

.license_dtl {
  button {
    width: 100%;
    justify-content: center;
    margin: 10px 0 0;
  }
}

.pos-eye {
  position: absolute;
  top: 6px;
  right: 6px;
  background: #ff000014;
  width: 30px;
  height: 30px;
  @include d_flx(center, center);
  flex-wrap: nowrap;
  border-radius: 50%;
}

.small-error {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: #e50019;
  text-align: center;
  width: 100%;
  display: block;
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
}

.view_driver {
  border: 1px solid var(--border-color);
  padding: 20px;
  border-radius: 10px;

  figure {
    max-width: 60px;
    max-height: 60px;
    overflow: hidden;

    img {
      @include img_contain(contain);
    }
  }

  .driver_cntnt {
    text-align: center;
  }

  .sub_title {
    width: 100%;
    padding-top: 10px;

    .btn_group {
      width: 100%;
      @include d_flx(center, center, 20px);
      margin-top: 20px;
    }

    .license_dtl {
      button {
        margin: 0;
      }
    }
  }
}

.no_sc_rating {
  max-height: 240px;
  overflow-y: auto;
  height: 100%;
}

.no_sc_ratings {
  max-height: 550px;
  overflow-y: auto;
  height: 100%;
}

.bid_group {
  button {
    height: 35px;
    padding: 10px 25px;
  }
}

ul.social_wp {
  .contactUs {
    margin-bottom: 20px;

    a {
      gap: 10px;
      justify-content: center;
    }
  }

  li {
    margin-bottom: 10px;

    a {
      @include d_flx(center, flex-start, 20px);

      span {
        color: #000;
      }

      figure {
        width: 40px;
        height: 40px;
      }

      p {
        color: #0000009c;
        letter-spacing: 0.2px;
        text-transform: lowercase;
        font-size: 15px;

        &:hover {
          transform: translateY(-2px);
          transition: all 0.4s;
          color: blue;
          text-decoration: underline;
        }
      }
    }
  }
}

.auction_wp {
  >ul {
    >li {
      .timer {
        position: absolute;
        font-size: 12px;
        top: 0;
        left: 0;
        background: #ff0000;
        padding: 6px;
        border-radius: 10px 0px 10px 0px;
        max-width: 85px;
        width: 100%;
        text-align: center;
        color: var(--border-white);
        font-weight: 600;
      }

      .status {
        position: absolute;
        font-size: 10px;
        top: 0;
        right: 0;
        background: #008000;
        padding: 6px;
        border-radius: 0px 10px 0px 10px;
        max-width: 85px;
        width: 100%;
        text-align: center;
        color: var(--border-white);
        font-weight: 600;
      }

      h5 {
        position: absolute;
        bottom: -7px;
        left: 0;
        right: 0;
        text-align: center;
        padding: 6px 0 10px;
        font-size: 13px;
        font-weight: 600;
        border-top: 2px solid #dedede;
        color: var(--primary-color);
        padding-top: 3px;

        &::before {
          content: "";
          width: 50px;
          height: 5px;
          background: #ffffff;
          background-repeat: no-repeat;
          position: absolute;
          top: -4px;
          left: 43%;
          right: 0;
        }
      }

      &:hover {
        box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.2);
        transition: all .3s;
        transition-duration: .4s;
      }
    }
  }

  .bid_cntnt {
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      color: var(--black);
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      color: var(--black);

      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .flg_dtls {
    margin-bottom: 12px;

    li {
      position: relative;

      figure {
        width: 100%;
        display: flex;
        margin: 0px 5px 20px;
        gap: 3px;
        height: 35px;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          @include img_contain(contain);
        }

        span {
          font-size: 15px;
          font-weight: 500;
          color: #000000d9;
          line-height: normal;
        }
      }

      p {
        text-align: center;
        font-size: 10px;
        max-width: 230px;
        margin-top: 15px;
        padding-right: 5px;
        padding-bottom: 10px;
        margin-left: auto;
        position: relative;
        min-height: auto;

        &:after {
          content: "";
          width: 2px;
          height: 100%;
          background-color: #dedede;
          position: absolute;
          top: 0;
          right: 0;
          background-repeat: no-repeat;
          transform: translateX(3px);
        }
      }

      &:last-child {
        p {
          padding-right: 0;
          border: unset;
          padding-left: 10px;

          &:last-child {
            &::after {
              content: unset;
            }
          }
        }

        &:after {
          content: unset;
        }
      }

      &:after {
        content: "";
        width: 20px;
        height: 20px;
        background: url("../assets/images/arrow-right-solid\ \(1\).svg") no-repeat;
        position: absolute;
        top: 3px;
        right: -12px;
      }

      &.noPickUp {
        width: 100% !important;
        @include d_flx(center, center);
        flex-direction: column;

        p {
          margin-left: 0;
          font-size: 12px;
          padding-bottom: 0;
        }
      }
    }
  }

  .btn_fixed {
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 0;
    right: 0;

    button {
      margin-bottom: 5px;
    }
  }

  .in_scroll {
    height: 201px;
    overflow-y: auto;
    margin-bottom: 20px;
  }

  .treetableDate .ui-widget-content {
    width: 80%; // or in pixel depends how much width you want.
  }

  .p-datepicker td>span {
    width: 20px !important;
    height: 20px !important;
  }
}

.noDataFound {
  width: 100%;

  figure {
    max-width: 120px;
    margin: 30px auto 15px;

    img {
      @include img_contain(cover);
    }
  }

  p {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
  }
}

.p-multiselect-item {
  display: flex;
  gap: 7px;
}

.p-multiselect-header {
  display: flex;
  gap: 7px;
}

.acoordion-container {
  .p-accordion {
    .p-accordion-tab {
      position: relative;

      a {
        width: 100%;
        @include d_flx(center, flex-start, 10px);
        margin-bottom: 10px;
        border-radius: 30px;
      }

      .p-icon-wrapper {
        position: absolute;
        right: 20px;
      }
    }

    .service-items {
      .p-carousel-content {
        .p-icon-wrapper {
          position: unset;
        }
      }
    }
  }

  .p-accordion-header.p-highlight a {
    border-color: var(--primary-color);

    span {
      color: var(--primary-color);
    }

    .p-icon-wrapper svg path {
      fill: var(--primary-color);
    }
  }
}

.borderBottom {
  li {
    margin-bottom: 5px;
    border-bottom: 1px solid #dedede;
    padding-bottom: 10px;

    span {
      font-size: 14px;
      color: #00000099;
      font-weight: 500;
      line-height: 30px;
    }
  }
}

.more {
  color: var(--primary-color) !important;
  text-decoration: underline;
  cursor: pointer;
}

.confirm-dilouge {
  .p-dialog-footer {
    button {
      background-color: var(--primary-color);
      border: none;

      .p-icon-wrapper {
        display: none;
      }
    }
  }
}

.selected_dfocus {

  button:focus,
  button:focus-visible,
  button:hover {
    border-color: none !important;
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
  }

  button {
    color: #6c757d !important;
  }
}

.selected_focus {
  color: var(--primary-color);
  border-color: var(--primary-color);

  button {
    color: var(--primary-color) !important;
  }

  .p-dropdown-label,
  .p-icon-wrapper {
    color: var(--primary-color) !important;
  }
}

.selected_focusButton .p-dropdown {
  border-color: var(--primary-color) !important;

  .p-dropdown-label,
  .p-icon-wrapper {
    color: var(--primary-color) !important;
  }
}

.selected_focusButton .p-dropdown .p-dropdown-trigger {
  border-color: var(--primary-color) !important;
}

.selected_focusButton .p-dropdown:focus,
.selected_focusButton .p-dropdown:focus-visible,
.selected_focusButton .p-dropdown:hover {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.3) !important;
  outline: var(--primary-color) !important;
}

.selected_dfocusButton {

  .p-dropdown:focus,
  .p-dropdown:focus-visible,
  .p-dropdown:hover {
    border-color: unset !important;
    box-shadow: unset !important;
    outline: none !important;
  }
}

.addvhl_ng {
  .small-error {
    position: unset;
  }
}

.p-carousel-prev,
.p-carousel-next {
  transform: scale(2.1);
  color: black;
  font-weight: bold;
}

.p-disabled,
.p-component:disabled {
  opacity: 1;
}

.p-carousel-prev:hover,
.p-carousel-next:hover {
  background-color: transparent;
}

.p-carousel-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.bid_sc {
  .vhl_card {
    >ul {
      width: 100%;

      li {
        text-align: center;
        border-bottom: 1px solid #dedede52;
        padding: 6px 0;
        margin: 0 -15px;

        &:last-child {
          border-bottom: none;

          a {
            padding: 2px 10px;
            position: relative;
            top: 10px;
            border-radius: 0px 5px 0 5px;
          }
        }

        a {
          font-size: 14px;
          font-weight: 500;
          color: var(--black);
          line-height: normal;
          letter-spacing: 0.2px;
        }

        &:first-child {
          a {
            color: var(--green);

            &.pending {
              color: #15ff15;
            }

            &.cancelled {
              color: #ff7300;
            }

            &.running {
              color: #16a637;
            }

            &.accepted {
              color: #16a637;
            }

            &.won {
              color: #0000ff;
            }

            &.lost {
              color: #000000;
            }

            &.ended {
              color: #ff0000;
            }

            &.paid {
              color: #ff0000;
            }

            &.done {
              color: #ff0000;
            }

            &.failed {
              color: #616161;
            }

            &.not_started {
              color: #ff7300;
            }

            &.in_process {
              color: #fffb3b;
            }

            &.assigned_driver {
              color: #fffb3b;
            }

            &.trip_started {
              color: #036a03;
            }

            &.trip_ended {
              color: #ff0000;
            }
          }
        }
      }
    }

    &.delivery_sc {
      margin: 20px 0 10px;

      .lt {
        border-right: 1px solid var(--border-color);

        p {
          figure {
            max-width: 50px;

            img {
              @include img_contain(contain);
            }
          }
        }

        span {
          text-align: center;
          font-size: 12px;
          max-width: 250px;
          padding: 10px 10px 15px 0;
          margin-inline: auto;
          width: 100%;
          display: block;
          line-height: 20px;
          position: relative;

        }

        &:last-child {
          border: none;
          margin-right: 6px;
        }
      }
    }

    .grid_columns {
      width: 100%;

      label {
        font-size: 16px;
        margin-bottom: 10px;
        color: var(--black);
        text-align: center;
      }

      ul.enq_hrs {
        @include d_flx(center, space-between);
        border: 1px solid var(--border-color);
        margin: 0 6px 20px -6px;

        li {
          width: 24%;
          border-right: 1px solid var(--border-color);
          margin: 0;
          border-bottom: none;
          text-align: center;
          padding: 5px 10px;

          &:last-child {
            border-right: none;
          }

          p {
            text-align: center;
            color: var(--primary-color);
            font-size: 21px;
            font-weight: 500;

            span {
              display: block;
              color: var(--black);
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      ul.data_list {
        @include d_flx(center);
        margin-bottom: 20px;
        border-left: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);

        li {
          border-right: 1px solid var(--border-color);
          border-top: 1px solid var(--border-color);
          margin: 0;
          padding: 8px 5px;

          p {
            font-size: 13px;
            font-weight: 500;
            color: #00000094;
            line-height: normal;
            text-align: center;

            span {
              color: var(--black);

              i {
                background: var(--primary-color);
                color: var(--bodybg);
                border: 1px solid var(--primary-color);
                border-radius: 50%;
                font-size: 13px;
                font-weight: bolder;
                position: relative;
                top: 0;
                left: 64px;
              }
            }
          }
        }
      }
    }
  }
}

.grid_columns:has(.enq_hrs) {
  margin-top: 20px
}

.enq_fdx {
  &.custm_flx {
    @include d_flx (stretch, flex-start, 10px);

    li {
      width: calc(100%/2 - (5px));
    }
  }

  .noFromLocation {
    h5 {
      &:before {
        content: unset;
      }
    }
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  .overlay {
    width: 100%;
    height: 100%;
    background: #008000b8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    animation: blink 0.7s ease-in-out 3; // 0.7s duration, repeats 3 times
    animation-fill-mode: forwards;
    @include d_flx(center, center);
    color: var(--white);
    border-radius: 10px;
    z-index: 9999;

    &.green {
      background: #008000b8;
    }

    &.red {
      background: #f10303b8;
    }
  }
}

.small-fig {
  figure {
    img {
      width: 25px;
    }
  }
}

.pac-container {
  z-index: 10000 !important;
  /* Ensure it's on top */
  position: absolute !important;
  /* Prevent layout issues */
}

.card_view:has(.noscroll) {
  overflow: hidden;

  .enq_fdx {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    padding: 10px 5px 10px 10px;
  }
}

.noData {
  figure {
    img {
      width: 37%;
      margin: 0 164px auto;
    }
  }

  p {
    font-size: 14px;
    font-weight: bolder;
  }
}

.custom_tabs {
  p-tabview {
    .p-tabview-nav-content {
      ul {
        width: 100%;

        >li {
          width: 50%;

          a {
            background: #fff;
            text-align: center;
            display: inline-flex;
            justify-content: center;
            width: 100%;
            color: #000;
            border-radius: 4px 0px 0px 0px;
            border: 1px solid #dededec4;
          }

          &:nth-child(2n) {
            a {
              background: #8080802e;
              border-radius: 0px 4px 0px 0px;
            }
          }

          .p-tabview-nav-link {
            border-bottom: none;
          }
        }
      }
    }

    .p-tabview-panels {
      padding: 0;

      .vhl_card {
        ul {
          li {
            padding: 10px 0;

            a {
              top: 0;
            }
          }
        }
      }
    }
  }
}

.table-responsive {
  table {

    th,
    td {
      border-bottom: 1px solid #dedede;
      padding: 10px;
    }
  }
}

.p-dialog-title {
  flex: 1;
  text-align: center;
}

.dot {
  width: 27px;
  height: 10px;
  border-radius: 50%;
  background: var(--primary-color);
  transform: translate(-3px, -10px);
  max-width: 12px;
}

.adp-marker2 {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 9999 !important;
  background-color: transparent !important;
  max-width: 300px;
}

.noti_count {
  position: absolute;
  top: -8px;
  right: -11px;
  font-size: 10px;
  background: #ef4444;
  color: #fff;
  font-weight: 500;
  padding: 0px 5px;
  border-radius: 30px;
}

.assign_dv {
  table {
    td {
      p {
        display: inline-flex;
        justify-content: center;
        max-width: 250px;
        width: 100%;
        position: relative;

        span {
          position: absolute;
          right: -20px;
        }
      }
    }
  }
}

.noPickUpDetails {
  margin: 0px 122px auto;
}

.fuel {
  display: flex !important;
  justify-content: center;
  gap: 13px;
}

.greyOut {
  color: #80808094;
}

.stars-line {
  justify-content: center !important;
}

.view_btn {
  p-button {
    width: 140px;
  }
}




/***direction RTL****/
body.rtl {
  .auction_wp {
    .flg_dtls li {
      &:after {
        left: -15px;
        right: unset;
        transform: scalex(-1);
      }

      p {
        padding-left: 10px;
        margin-right: auto;

        &:after {
          right: unset;
          left: -5px;
        }
      }

      &:last-child {
        padding-left: 0;
        padding-right: 10px;
      }


    }

    ul {
      >li {
        h5 {
          &:before {
            right: 43%;
            left: 0;
          }
        }
      }
    }
  }

  .file-upload {
    .file-select-name {
      p {
        transform: translate(-10px, 5px);
      }
    }

    .download {
      right: unset;
      left: 10px;
    }

    figure.info_icon {
      top: 12px;
      right: unset;
      left: 50px;
    }
  }

  .card_view {
    margin-right: 20px;
    margin-left: unset;
  }

  .p-carousel-prev,
  .p-carousel-next {
    transform: scalex(-1);

    .p-icon-wrapper {
      transform: scale(1.8);
    }
  }

  fieldset {
    width: 100%;
  }

  .small-error {
    text-align: right;
  }

  .bid_sc {
    .vhl_card {
      .lt p {
        flex-direction: row-reverse;
      }
    }
  }

  .input_flag {
    .multi_selected {
      right: 10px;
      left: unset;
    }

    .p-multiselect {
      border-right: unset;
      border-left: 1px solid var(--border-color);
    }
  }
}

.header-switch {
  .p-inputswitch {
    position: relative;
    top: 1px;
    left: 0;
  }
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--primary-color);
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  border: none;
  box-shadow: none;
  outline: none;
}

.profile-data {
  li {
    width: 69% !important;
  }
}

.p-multiselect-items .p-multiselect-item .custom-grey-option {
  background-color: #f0f0f0 !important;
}

.p-multiselect-item:has(.custom-grey-option) {
  background-color: #f0f0f0 !important;

  .p-checkbox-box {
    background: #f0f0f0 !important;
  }
}


.dotsLi {
  li {
    list-style: inside;
  }
}

/***direction RTL end****/